import React, { useEffect, useState } from 'react'
import Arrow from '@partials/Arrow'
import Swiper, { SwiperOptions } from 'swiper'
import '../../assets/styles/components/Slider.scss'

type Props = {
  children: React.ReactElement[]
  className: string
  settings?: SwiperOptions
  animate?: boolean
  mouseControl?: boolean
  onChange?: (swiper: Swiper) => void
}

const Slider: React.FC<Props> = ({ children, className, settings, animate, mouseControl, onChange }) => {
  const [currentSwiper, setCurrentSwiper] = useState<Swiper>()

  useEffect(() => {
    let sliderSettings = { ...settings, draggable: true, speed: 800 }

    if (animate) {
      sliderSettings = {
        ...sliderSettings,
        on: {
          init: () => doAnimation(0),
        },
      }
    }

    const newSwiper = new Swiper(`.${className}`, sliderSettings)
    setCurrentSwiper(newSwiper)
  }, [])

  useEffect(() => {
    if (currentSwiper) {
      if (animate) {
        currentSwiper.on('slideChangeTransitionEnd', () => {
          doAnimation(currentSwiper.activeIndex)
        })
      }

      if (onChange) {
        currentSwiper.on('slideChange', () => onChange(currentSwiper))
      }

      if (mouseControl) {
        window.addEventListener('wheel', (e) => mouseWheelControl(e, currentSwiper))
      }
      window.addEventListener('keydown', handleArrowKeys)

      return () => {
        window.removeEventListener('wheel', (e) => mouseWheelControl(e, currentSwiper))
        window.removeEventListener('keydown', handleArrowKeys)
      }
    }
  }, [currentSwiper])

  function handleArrowKeys(e: KeyboardEvent) {
    if (currentSwiper) {
      const key = e.code
      const dir = settings?.direction ?? 'horizontal'
      const slideNext = () => currentSwiper.slideNext()
      const slidePrev = () => currentSwiper.slidePrev()

      if (dir === 'horizontal') {
        if (key === 'ArrowLeft') slidePrev()
        if (key === 'ArrowRight') slideNext()
      }

      if (dir === 'vertical') {
        if (key === 'ArrowUp') slidePrev()
        if (key === 'ArrowDown') slideNext()
      }
    }
  }

  const doAnimation = (index: number) => {
    if (index !== undefined) {
      //Animation classes
      const classes = ['animated', 'fadeIn']

      //Get all slides
      const slides = Array.from(document.querySelectorAll('.swiper-slide'))
      slides.map((slide) => {
        const elements = Array.from(slide.querySelectorAll('.animate'))
        return elements.map((el) => el.classList.remove(...classes))
      })

      //Get active slide
      const slide = slides[index]

      //Animate slide
      const shouldAnimate = Array.from(slide.querySelectorAll('.animate'))
      shouldAnimate.map((el) => el.classList.add(...classes))
    }
  }

  const mouseWheelControl = (e: WheelEvent, swiper: Swiper): void => {
    const delta = Math.sign(e.deltaY)

    if (!swiper.animating) {
      if (delta === 1) {
        return swiper.slideNext()
      } else {
        return swiper.slidePrev()
      }
    }
  }

  return (
    <div className={`swiper-container ${className || ''}`}>
      <div className="swiper-wrapper">
        {React.Children.map(children, (slide) =>
          React.cloneElement(slide, {
            className: `swiper-slide ${slide?.props.className}`,
          }),
        )}
      </div>
      {settings?.navigation ? (
        <div className="swiper-nav-wrapper">
          <div className="swiper-button-prev">
            <Arrow />
          </div>
          <div className="swiper-button-next">
            <Arrow />
          </div>
        </div>
      ) : null}
      <div className="swiper-pagination"></div>
    </div>
  )
}

export default Slider
